<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
       strModel="Cadastro/Exame"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :loading="loading"
      :filter="formFiltros"
      @register="onRegister"
      @edit="onEdit"
      @search="getFuncao"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getFuncao();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Código eSocial",
          sortable: true,
          value: "strCodigoEsocial",
        },
        {
          text: "Código TUSS",
          sortable: true,
          value: "strCodigoTuss",
        },
        {
          text: "Categoria",
          sortable: true,
          value: "strCategoria",
        },
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "cadastro.exame.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "cadastro.exame.edit",
        params: { intId: data.intId },
      });
    },

    getFuncao() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request.get("Cadastro/Exame", this.formFiltros).then((objResult) => {
        if (objResult.status == 200) {
          this.objPagination = objResult.result;
          this.loading = false;

          let arrayRows = [];

          objResult.result.data.forEach((objExame) => {

            console.log('objExame',objExame)
            let item = {
              intId: objExame.intId,
              strCodigoEsocial: objExame.strCodigoEsocial,
              strCodigoTuss: objExame.strCodigoTuss,
              strCategoria: objExame.tipo_categoria ? objExame.tipo_categoria.strNome : '---',
              strNome: objExame.strNome,
              _item: objExame,
            };

            arrayRows.push(item);
          });

          this.arrayRows = arrayRows;
        }
      });
    },
  },
};
</script>
